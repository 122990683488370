import React from "react";
import { Link } from "gatsby";
import Container from "../../../utils/Container";
import { footerLinks } from "../../../constants/footer-en-mea";
import { getCopyright, getSocialMediaLink } from "../../../utils";
const Footer = () => {
  return (
    <footer>
      <Container className="max-w-[125rem] mx-aut0 my-0">
        <div className="grid lg:grid-cols-4 md:grid-cols-2 justify-items-start py-6 mt-3">
          <div className="h-12  w-[198px] mt-4">
            <img src="https://entomo.co/wp-content/uploads/2021/02/001_Entomo_Logo_Colour.svg" alt="logo" />
          </div>
          <div className="text-[#333333] text-base mt-4  mb-1 font-extrabold">
            <h3>Middle East HQ</h3>
            <p className="text-[#4d4d4d] text-sm font-normal">
              The Onyx Tower 1,Office 910,
              <br />
              P.O.Box 410870
              <br />
              Dubai,United Arab Emirates
              <br />
              <Link to="tel:+971 4399 52 53">T: +971 4399 52 53</Link>
            </p>
          </div>
          <div className="text-black text-base font-bold sm:pt-16 md:pt-0">
            {footerLinks.map((footerLink) => {
              return (
                <Link to={footerLink.link} key={footerLink.text} className="my-2">
                  <p>{footerLink.text}</p>
                </Link>
              );
            })}
          </div>
          <div className="h-10">{getSocialMediaLink(["facebook", "twitter", "linkedin"])} </div>
        </div>
      </Container>
      {getCopyright()}
    </footer>
  );
};

export default Footer;
