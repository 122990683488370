import React from "react";
import NavMenuRequestDemo from "../components/NavMenuRequestDemo";

import imageSolutionsMenu from "../images/solutions_menu.png";
import imageMenuResource from "../images/menu_resource.png";
import imageAboutMenu from "../images/about_menu.png";

export const menuItems = [
  {
    name: "Our platform",
    component: "Our platform",
    title: "Features",
    description: "Powering Enterprises of Tomorrow​. Perform. Engage. Act. Grow.",
    img: imageSolutionsMenu,
    sub: [
      {
        name: "Perform",
        description: "Measure and drive performance, everyday",
        url: "/en-mea/platform/#perform",
      },
      {
        name: "Engage",
        description: "Drive engagement within and across teams",
        url: "/en-mea/platform/#engage",
      },
      {
        name: "Act",
        description: "Manage distributed and remote teams",
        url: "/en-mea/platform/#act",
      },
      {
        name: "Grow",
        description: "Build a tomorrow-ready workforce",
        url: "/en-mea/platform/#grow",
      },
      {
        name: "Features",
        url: "/en-mea/all-products/features/",
      },
    ],
  },
  {
    name: "Our products",
    component: "Our products",
    title: "Our Products",
    description: "Make every day a purpose-driven workday with our solutions.",
    img: imageSolutionsMenu,
    action: {
      name: "View All",
      url: "/en-mea/all-products/",
    },
    sub: [
      {
        name: "Strategic planning",
        url: "/en-mea/all-products/strategic-planning/",
      },
      {
        name: "Employee performance management",
        url: "/en-mea/all-products/everyday-performance/",
      },
      {
        name: "Analytics",
        url: "/en-mea/all-products/everyday-insights/",
      },
      {
        name: "Career management",
        url: "/en-mea/all-products/careers-tomorrow/",
      },
    ],
  },
  {
    name: "Inspiration",
    component: "Inspiration",
    title: "Inspirations",
    description: "Industry insights, inspiring stories, innovative ideas – everything in one place.",
    img: imageMenuResource,
    action: {
      name: "View All",
      url: "/en-mea/resources/",
    },
    sub: [
      {
        name: "Blog",
        url: "/en-mea/resources/blog/",
      },
      {
        name: "E Books & WhitePapers",
        url: "/en-mea/resources/whitepaper-e-book/",
      },
      {
        name: "Events & Webinars",
        url: "/en-mea/resources/events-webinar/",
      },
      {
        name: "Success Stories",
        url: "/en-mea/resources/success-stories/",
      },
      {
        name: "News & Press Releas",
        url: "/en-mea/news-press-releases/",
      },
    ],
  },
  {
    name: "About us",
    component: "About us",
    title: "Our Team",
    description: "Want to know more about entomo? You have come to the right place",
    img: imageAboutMenu,
    sub: [
      {
        name: "Why entomo",
        url: "/en-mea/why-entomo/",
      },
      {
        name: "Our team",
        url: "/en-mea/about/our-team/",
      },
    ],
  },
  {
    name: "Contact",
    component: "Contact",
    url: "/en-mea/contact/",
  },
  {
    name: "tel",
    component: "+971 4399 52 53",
    url: "tel:+97143995253",
    urlType: "external",
  },
  {
    name: "request demo button",
    component: <NavMenuRequestDemo text="Request Demo" url={"/en-mea/request-demo/"} />,
  },
];
