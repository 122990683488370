const footerLinks = [
  {
    text: "About Us",
    link: "https://entomo.co/en-mea/about/our-team/",
  },
  {
    text: "Inspiration",
    link: "https://entomo.co/en-mea/resources/",
  },
  {
    text: "Request Demo",
    link: "https://entomo.co/en-mea/request-demo/",
  },
  {
    text: "Visit our global site",
    link: "https://www.entomo.co/",
  },
];

export { footerLinks };
